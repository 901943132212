/* eslint-disable react-hooks/exhaustive-deps */
import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Input,
  Box,
  Checkbox,
} from '@mui/material';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import withToggle from 'components/hocs/withToggle';
import RoutePagination from 'components/pagination/RoutePagination';
import Filters from 'components/products/Filters';
import Product from 'components/products/Product';
import ProductPopUp from 'components/products/ProductPopUp';
import SelectedFiltersComp from 'components/selected-filters/SelectedFilters';
import Sorting from 'components/sorting/Sorting';
import { setGeneralLoading } from 'models/actions/generalActions';
import {
  getInitialProductsSeries,
  setCurrentProductsPage,
  removeSelectedFilter,
  removeSelectedCodeFilter,
  downloadProductsCsv,
  setProductsSorting,
  setBulkProducts,
  getAllProducts,
  setAllProducts,
  applyMassDiscount,
} from 'models/actions/productsActions';
import {
  adminProducts,
  productsPagination,
  productsSelectedFilters,
  filterCategories,
  filterSubCategories,
  filterBrands,
  productsSorting,
  allProducts,
} from 'models/selectors/productsSelectors';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as XLSX from 'xlsx';

const Products = ({ toggleValue, setToggleValue }) => {
  const dispatch = useDispatch();
  const [file, setFile] = useState('');
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [massDiscountPopupOpen, setMassDiscountPopupOpen] = useState(false);
  const [globalDiscount, setGlobalDiscount] = useState(0);
  const [allChecked, setAllChecked] = useState(false);

  const [productPopupOpen, setProductPopupOpen] = useState({
    open: false,
    productOpenForEdit: '',
  });
  const products = useSelector(adminProducts);
  const allTheProducts = useSelector(allProducts);
  const sorting = useSelector(productsSorting);
  const productsFilterCategories = useSelector(filterCategories);
  const productsFilterSubCategories = useSelector(filterSubCategories);
  const productsFilterBrands = useSelector(filterBrands);
  const pagination = useSelector(productsPagination);
  const selectedFilters = useSelector(productsSelectedFilters);
  const {
    selectedCategory,
    selectedSubCategory,
    selectedBrand,
    selectedCode,
    selectedIsActive,
    selectedIsNew,
  } = selectedFilters;

  const toggleProductOpenWithId = (productId) => {
    setProductPopupOpen({
      ...productPopupOpen,
      open: true,
      productOpenForEdit: productId,
    });
  };

  const handleConvert = () => {
    if (file && file?.name) {
      dispatch(setGeneralLoading(true));
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet, { defval: '' });

        dispatch(setBulkProducts({ bulkProducts: json }));
      };
      reader.readAsBinaryString(file);
    }
  };

  const handleMassDiscount = () => {
    setMassDiscountPopupOpen(true);
  };

  useEffect(() => {
    dispatch(setGeneralLoading(true));
    dispatch(setAllProducts([]));
    dispatch(getInitialProductsSeries());
  }, []);

  useEffect(() => {
    if (massDiscountPopupOpen) {
      dispatch(getAllProducts());
    }
  }, [massDiscountPopupOpen]);

  const handleSelectedProducts = (value) => {
    let newSelectedProducts = [];

    if (selectedProducts.includes(value)) {
      newSelectedProducts = selectedProducts?.filter((pr) => pr !== value);
    } else {
      newSelectedProducts = [...selectedProducts, value];
    }

    setSelectedProducts(newSelectedProducts);
  };

  useEffect(() => {
    if (allChecked) {
      setSelectedProducts([]);
      setSelectedProducts(
        allTheProducts?.map((pr) => pr?.productId.toString()),
      );
    } else {
      setSelectedProducts([]);
    }
  }, [allChecked]);

  const inputFileRef = useRef(null);

  return (
    <div className="content products-page">
      <Dialog
        open={massDiscountPopupOpen}
        onClose={() => setMassDiscountPopupOpen(false)}>
        <DialogTitle sx={{ display: 'block' }} fullWidth>
          <Box
            fullWidth
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gap={2}>
            <div>ΕΝΕΡΓΟΠΟΙΗΣΗ ΜΑΖΙΚΗΣ ΕΚΠΤΩΣΗΣ</div>
            <div>
              <IconButton
                aria-label="close"
                onClick={() => setMassDiscountPopupOpen(false)}>
                <CloseIcon />
              </IconButton>
            </div>
          </Box>
        </DialogTitle>
        <DialogContent>
          <div className="p12">
            Παρακαλώ εισάγετε το ποσοστό έκπτωσης που θέλετε για όλα τα προϊόντα
            (αριθμός ακέραιος)
          </div>
          <div className="p12">
            <Box
              display="flex"
              justifyContent="flex-start"
              alignItems="flex-start">
              <Checkbox
                value={allChecked}
                checked={allChecked}
                id="checkAll"
                onChange={(e) => setAllChecked(!allChecked)}
              />
              <InputLabel htmlFor="checkAll">Check/Uncheck all</InputLabel>
            </Box>
          </div>

          <div className="p12">
            <ul className="products-list">
              {allTheProducts?.map((pr) => (
                <li key={pr.productId}>
                  <InputLabel htmlFor={pr.productId}>
                    {pr.productTitle}
                  </InputLabel>
                  <Checkbox
                    value={pr.productId}
                    checked={selectedProducts.includes(pr.productId.toString())}
                    id={pr.productId}
                    onChange={(e) => handleSelectedProducts(e.target.value)}
                  />
                </li>
              ))}
            </ul>
          </div>
          <div className="p12">
            <FormControl fullWidth>
              <InputLabel htmlFor="brand-name">Έκπτωση</InputLabel>
              <Input
                fullWidth
                id="global-discount"
                value={globalDiscount}
                onChange={(e) => setGlobalDiscount(e.target.value)}
              />
            </FormControl>
          </div>
          <div className="p12">
            <button
              className="button lightblue"
              onClick={() => {
                if (selectedProducts.length > 0 && !isNaN(globalDiscount)) {
                  dispatch(setGeneralLoading(true));
                  dispatch(
                    applyMassDiscount({
                      products: selectedProducts,
                      discount: globalDiscount,
                    }),
                  );
                }
              }}>
              ΕΦΑΡΜΟΓΗ
            </button>
          </div>
        </DialogContent>
      </Dialog>
      <div className="row">
        <div className="wrapper">
          <Input value={file?.name || ''} readOnly />
          <input
            className="uploadProducts"
            type="file"
            accept=".xls, .xlsx"
            ref={inputFileRef}
            onChange={(e) => setFile(e.target.files[0])}
          />
          <button className="button lightblue" onClick={handleConvert}>
            ΑΝΕΒΑΣΤΕ ΜΑΖΙΚΑ ΠΡΟΙΟΝΤΑ
          </button>
          <button
            className="button lightblue ml-12"
            onClick={handleMassDiscount}>
            ΜΑΖΙΚΗ ΕΚΠΤΩΣΗ ΣΕ ΟΛΑ ΤΑ ΠΡΟΙΟΝΤΑ
          </button>
        </div>
      </div>
      <div className="row">
        <div className="wrapper">
          <div className="page-title">
            Προϊόντα
            <Tooltip title="Κατεβάστε σε αρχείο">
              <i
                onClick={() => {
                  dispatch(setGeneralLoading(true));
                  dispatch(downloadProductsCsv());
                }}
                className="icon-file-excel pointer"
              />
            </Tooltip>
          </div>
        </div>
      </div>
      {productPopupOpen?.open && (
        <ProductPopUp
          open={productPopupOpen?.open}
          productOpenForEdit={productPopupOpen?.productOpenForEdit}
          handleClose={() =>
            setProductPopupOpen({
              ...productPopupOpen,
              open: false,
              productOpenForEdit: '',
            })
          }
        />
      )}
      {(selectedCode !== '' ||
        selectedCategory ||
        selectedSubCategory ||
        selectedIsActive ||
        selectedIsNew ||
        selectedBrand) && (
        <div className="row">
          <div className="wrapper">
            <SelectedFiltersComp
              selectedFilters={[
                {
                  label: 'Κατηγορία',
                  value: selectedCategory,
                  condition: '&&',
                  removeFn: removeSelectedFilter,
                  type: 'selectedCategory',
                  neededArray: productsFilterCategories,
                },
                {
                  label: 'Υποκατηγορία',
                  value: selectedSubCategory,
                  condition: '&&',
                  removeFn: removeSelectedFilter,
                  type: 'selectedSubCategory',
                  neededArray: productsFilterSubCategories,
                },
                {
                  label: 'Brand',
                  value: selectedBrand,
                  condition: '&&',
                  removeFn: removeSelectedFilter,
                  type: 'selectedBrand',
                  neededArray: productsFilterBrands,
                },
                {
                  label: 'Ενεργό',
                  value: selectedIsActive,
                  condition: '',
                  removeFn: removeSelectedFilter,
                  type: 'selectedIsActive',
                },
                {
                  label: 'Καινούριο',
                  value: selectedIsNew,
                  condition: '',
                  removeFn: removeSelectedFilter,
                  type: 'selectedIsNew',
                },
                {
                  label: 'Κωδικός',
                  value: selectedCode,
                  condition: '&&',
                  removeFn: removeSelectedCodeFilter,
                  type: 'selectedCode',
                },
              ]}
            />
          </div>
        </div>
      )}
      <Drawer
        anchor="right"
        open={toggleValue?.right}
        className="filters-drawer"
        onClose={setToggleValue('right', false)}>
        <div className="products-filters-section">
          <Filters setToggleValue={setToggleValue} />
        </div>
      </Drawer>
      {products?.length > 0 && (
        <div className="row">
          <div className="wrapper">
            <div className="sorting-section for-products">
              <>
                <RoutePagination
                  setCurrentPage={setCurrentProductsPage}
                  pagination={pagination}
                />
                <Sorting
                  sorting={sorting}
                  sortingAction={setProductsSorting}
                  options={[
                    { value: 1, name: 'Ανα τιμή (αύξουσα)' },
                    { value: 2, name: 'Ανα τιμή (φθίνουσα)' },
                  ]}
                />
              </>
            </div>
          </div>
        </div>
      )}
      <div className="row">
        <div className="wrapper">
          <div className="products-crud-actions">
            <span>{`(${pagination?.total} προϊόντα)`}</span>
            <button
              className="button add"
              onClick={() =>
                setProductPopupOpen({
                  ...productPopupOpen,
                  open: true,
                  productOpenForEdit: '',
                })
              }>
              ΔΗΜΙΟΥΡΓΙΑ
            </button>
            <button
              className="button lightblue"
              onClick={setToggleValue('right', true)}>
              ΦΙΛΤΡΑ
            </button>
          </div>
          {products?.length > 0 ? (
            <div className="products">
              <Table className="products-grid responsive-table">
                <TableHead>
                  <TableRow>
                    <TableCell>ΤΙΤΛΟΣ</TableCell>
                    <TableCell>ΚΩΔΙΚΟΣ</TableCell>
                    <TableCell>ΑΡΧΙΚΗ ΤΙΜΗ</TableCell>
                    <TableCell>ΤΙΜΗ</TableCell>
                    <TableCell>ΑΠΟΘΕΜΑ</TableCell>
                    <TableCell>ΚΑΙΝΟΥΡΙΟ</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {products?.map((product) => (
                    <Product
                      product={product}
                      key={product?.productId}
                      toggleProductOpen={toggleProductOpenWithId}
                    />
                  ))}
                </TableBody>
              </Table>
            </div>
          ) : (
            <div className="not-found">
              <h2>Δεν βρέθηκαν προϊόντα με αυτά τα κριτήρια.</h2>
            </div>
          )}
        </div>
      </div>
      <div className="row">
        <div className="wrapper">
          <div className="sorting-section for-products">
            {products?.length > 0 && (
              <>
                <RoutePagination
                  setCurrentPage={setCurrentProductsPage}
                  pagination={pagination}
                />
                <Sorting
                  sorting={sorting}
                  sortingAction={setProductsSorting}
                  options={[
                    { value: 1, name: 'Ανα τιμή (αύξουσα)' },
                    { value: 2, name: 'Ανα τιμή (φθίνουσα)' },
                  ]}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withToggle(Products);
