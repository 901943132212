/* eslint-disable react-hooks/exhaustive-deps */
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Tooltip from '@mui/material/Tooltip';
import { setGeneralLoading } from 'models/actions/generalActions';
import {
  updateHomeLastBanner,
  addHomeLastBanner,
  setHomeLastBannerImage,
} from 'models/actions/settingsActions';
import { homeLastBannerImage } from 'models/selectors/settingsSelectors';
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

const HomeLastBannerPopup = ({ open, homeBanner, hbId, handleClose }) => {
  const inputFileRef = useRef(null);
  const dispatch = useDispatch();
  const hbImage = useSelector(homeLastBannerImage);

  const [homeBannerPopup, setHomeBannerPopup] = useState({
    description: '',
    link: '',
    isVideo: 0,
  });

  const [error, setError] = useState('');

  const handleFileChange = (e) => {
    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };

    dispatch(setHomeLastBannerImage(img));
  };

  const onFileClick = () => {
    inputFileRef.current.click();
  };

  useEffect(() => {
    dispatch(setHomeLastBannerImage({ preview: '', data: '' }));
    setHomeBannerPopup(homeBanner || homeBannerPopup);
    if (hbId !== '') {
      dispatch(
        setHomeLastBannerImage({
          preview: `${process.env.REACT_APP_IMAGE_URL}/${homeBanner?.imgHref}`,
          data: { name: homeBanner?.imgHref },
        }),
      );
    }

    return () => {
      setHomeBannerPopup({
        title: '',
        link: '',
        isVideo: 0,
      });
      dispatch(setGeneralLoading(false));
      dispatch(setHomeLastBannerImage({ preview: '', data: '' }));
    };
  }, []);

  const handleHomeBannerUpdate = () => {
    if (homeBannerPopup && homeBannerPopup?.description === '') {
      setError('Error');

      return false;
    }

    setError('');
    dispatch(setGeneralLoading(true));

    if (hbId !== '') {
      dispatch(
        updateHomeLastBanner({
          ...homeBannerPopup,
          image: hbImage,
          type: hbImage?.data?.type,
        }),
      );
    } else {
      dispatch(
        addHomeLastBanner({
          ...homeBannerPopup,
          image: hbImage,
          type: hbImage?.data?.type,
        }),
      );
    }
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description">
        <DialogTitle id="scroll-dialog-title">
          <span>
            {hbId === ''
              ? 'ΔΗΜΙΟΥΡΓΙΑ ΚΕΝΤΡΙΚΟΥ SLIDE'
              : homeBannerPopup?.description}
          </span>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers={true}>
          <div className="product-popup-fields">
            <div className="product-popup-field">
              <FormControl>
                <Box
                  gap={2}
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center">
                  <span onClick={onFileClick}>Επιλέξτε φωτό</span>
                  <Input value={hbImage?.data?.name || ''} readOnly />
                  {hbImage?.data?.name?.length > 1 && (
                    <img
                      className="img-preview"
                      src={hbImage?.preview || ''}
                      alt=""
                    />
                  )}
                  <input
                    type="file"
                    accept="images/*"
                    ref={inputFileRef}
                    onChange={handleFileChange}
                    hidden
                  />
                  {hbImage?.data?.name && (
                    <Tooltip title="Διαγραφή εικόνας">
                      <i
                        onClick={() => {
                          inputFileRef.current.value = '';
                          dispatch(
                            setHomeLastBannerImage({ preview: '', data: '' }),
                          );
                        }}
                        className="delete-product-image icon-cancel-circled"
                      />
                    </Tooltip>
                  )}
                </Box>
              </FormControl>
            </div>
            <div className="product-popup-field">
              <FormControl>
                <InputLabel htmlFor="home-banner-title">Τίτλος</InputLabel>
                <Input
                  id="home-banner-title"
                  error={error !== ''}
                  value={
                    homeBannerPopup?.description
                      ? homeBannerPopup?.description
                      : ''
                  }
                  onChange={(e) =>
                    setHomeBannerPopup({
                      ...homeBannerPopup,
                      description: e.target.value,
                    })
                  }
                />
                {error && (
                  <span className="error-span">
                    Πρέπει να συμπληρώσετε τίτλο.
                  </span>
                )}
              </FormControl>
            </div>
            <div className="product-popup-field">
              <FormControl>
                <InputLabel htmlFor="home-banner-link">Link</InputLabel>
                <Input
                  id="home-banner-link"
                  value={homeBannerPopup?.link ? homeBannerPopup?.link : ''}
                  onChange={(e) =>
                    setHomeBannerPopup({
                      ...homeBannerPopup,
                      link: e.target.value,
                    })
                  }
                />
              </FormControl>
            </div>
            <div className="product-popup-field isColumns">
              <InputLabel htmlFor="home-banner-isvideo">Video?</InputLabel>
              <Checkbox
                id="home-banner-isvideo"
                checked={homeBannerPopup?.isVideo}
                onChange={(e) =>
                  setHomeBannerPopup({
                    ...homeBannerPopup,
                    isVideo: !homeBannerPopup.isVideo,
                  })
                }
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <button onClick={handleClose} className="button delete">
            ΚΛΕΙΣΙΜΟ
          </button>
          <button onClick={handleHomeBannerUpdate} className="button add">
            {hbId !== '' ? 'ΕΝΗΜΕΡΩΣΗ' : 'ΔΗΜΙΟΥΡΓΙΑ'}
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default HomeLastBannerPopup;
